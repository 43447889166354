import $ from "jquery"
import "datatables.net-se"
import "datatables.net-se/css/dataTables.semanticui.css"
import { trilingual } from "../../viz/i18n"
import { State } from "../../util/state"
import { initializeDataTableSelect } from "./select-table"
import { getLanguage } from "../../util/language"

const texts = {
  info: trilingual(
    "Showing _START_ to _END_ of _TOTAL_ entries",
    "Einträge _START_ bis _END_ von _TOTAL_",
    "Affichage du _START_ à la _END_ du _TOTAL_ des entrées"
  ),
  infoEmpty: trilingual(
    "Showing 0 to 0 of 0 entries",
    "Einträge 0 bis 0 von 0",
    "Affichage de 0 à 0 sur 0 entrées"
  ),
  infoFiltered: trilingual(
    "(filtered from _MAX_ total entries)",
    "(aus insgesamt _MAX_ Einträgen)",
    "(filtré à partir de _MAX_ entrées totales)"
  ),
  lengthMenu: trilingual(
    "Show _MENU_ entries",
    "Zeige _MENU_ Einträge",
    "Afficher les entrées du _MENU_"
  ),
  search: "", // remove search label
  searchPlaceholder: trilingual("Search", "Suche", "Recherche"),
  zeroRecords: trilingual(
    "No matching records found",
    "Keine passenden Einträge gefunden",
    "Aucune entrée correspondante trouvée"
  ),
  paginate: {
    first: "", // not used
    last: "", // not used
    next: "<i class='chevron right icon'></i>",
    previous: "<i class='chevron left icon'></i>",
  },
}

/* prettier-ignore */
function buildDomLayout({
  hideHeader,
  hideFooter,
}: {
  hideHeader?: boolean
  hideFooter?: boolean
} = {}) {
  const headerTemplate =
    !hideHeader ? (
      "<'row unpadded'" +
        "<'sixteen wide column'<'dt-header'l<'button-container'>f>>" +
      ">"
    ) : "<'row'>"

  const footerTemplate =
    !hideFooter ? (
      "<'row unpadded'" +
        "<'right aligned column'<'dt-pagination'ip>>" +
      ">"
    ) : "<'row'>"

  return (
    "<'ui stackable grid'" +
    headerTemplate +
    "<'row dt-table'" +
      "<'sixteen wide column'tr>" +
    ">" +
    footerTemplate +
    ">"
  )
}

function initializeSorting() {
  const jQueryFn = jQuery.fn as any
  if (
    !jQueryFn?.dataTableExt ||
    jQueryFn.dataTableExt.type.order["simple-date-pre"]
  ) {
    return
  }

  // Patch default string sorting behavior if Intl is available
  if (window.Intl) {
    const sortingMethods = jQueryFn.dataTableExt.type.order
    const collator = new Intl.Collator(getLanguage())

    sortingMethods["simple-string-pre"] = sortingMethods["string-pre"]

    delete sortingMethods["string-pre"]
    sortingMethods["string-asc"] = collator.compare
    sortingMethods["string-desc"] = function (a: string, b: string) {
      return collator.compare(a, b) * -1
    }
  }

  jQuery.extend(jQueryFn.dataTableExt.type.order, {
    "simple-date-pre": function (date: string) {
      if (typeof date !== "string") {
        return 0
      }

      date = date.replace(" ", "")

      let day, month, year
      let dateParts = date.split(/[\.\/]/)
      if (dateParts.length === 1 && date.indexOf("-") !== -1) {
        dateParts = date.split("-")
        ;[year, month, day] = dateParts
      } else {
        ;[day, month, year] = dateParts
      }

      const val = parseInt(year + month + day, 10)
      return isNaN(val) ? 0 : val
    },
    "simple-date-asc": function (a: number, b: number) {
      return a < b ? -1 : a > b ? 1 : 0
    },
    "simple-date-desc": function (a: number, b: number) {
      return a < b ? 1 : a > b ? -1 : 0
    },
  })
}

function patchPaginationRenderer() {
  const jQueryFn = jQuery.fn as any
  const renderer = jQueryFn?.dataTableExt?.renderer?.pageButton?.semanticUI
  if (typeof renderer !== "function" || !!renderer.patched) {
    return
  }

  const patchedRenderer = function (...args: unknown[]) {
    // Call original renderer
    renderer(...args)

    // Remove stackable class after rendering
    const host = args[1] as Element
    $(host)
      .find(".ui.stackable.menu")
      .removeClass("stackable")
      .addClass("unstackable")
  }
  patchedRenderer.patched = true
  jQueryFn.dataTableExt.renderer.pageButton.semanticUI = patchedRenderer
}

function createPersistOrderCallback(key: string) {
  return function (this: DataTables.Settings) {
    const table = $(this).DataTable()
    const newOrder = table.order()
    if (newOrder) {
      State.setValue(key, newOrder)
    }
  }
}
function getPersistedOrder(
  key: string,
  defaultOrder: DataTables.Settings["order"],
  ignoredColumns: number[]
) {
  const order = State.getValue(key)
  const isValidOrder =
    Array.isArray(order) &&
    order.length !== 0 &&
    ignoredColumns.indexOf(order[0][0]) === -1

  return isValidOrder ? order : defaultOrder
}

function initializeInnovationRanking() {
  $("table.ranking.dataTable").each((_, element) => {
    const table = $(element)
    const rowCount = table.find("> tbody > tr").length
    table.DataTable({
      paging: rowCount > 25,
      pageLength: 50,
      lengthChange: true,
      ordering: true,
      info: true,
      searching: true,
      scrollX: true,
      columnDefs: [
        // Order ranking values descending first
        {
          targets: [3, 4, 5, 6, 7, 8],
          orderSequence: ["desc", "asc"],
        },
      ],
      language: texts,
    })
  })

  $("#innovation-ranking-menu .item").tab({
    onVisible: function () {
      // Adjust column sizes of hidden table that now becomes visible
      $(this).find("table.dataTable").DataTable().columns.adjust().draw()
    },
  })
}

function initializeCoverageTables() {
  const reinitializeTooltipsCallback = function (
    this: DataTables.SettingsLegacy
  ) {
    $(this)
      .find(".tooltip-host")
      .popup({ position: "top center", hoverable: true })
  }

  $("table.countries.dataTable").DataTable({
    paging: false,
    ordering: true,
    order: [[0, "asc"]],
    searching: false,
    lengthChange: false,
    info: false,
    language: texts,
    dom: "t",
    drawCallback: reinitializeTooltipsCallback,
  })

  $("table.sources.dataTable").DataTable({
    paging: false,
    ordering: true,
    order: [],
    searching: true,
    language: texts,
    dom: "t",
    columnDefs: [
      { targets: [1], searchable: true },
      { targets: "_all", searchable: false },
    ],
    drawCallback: reinitializeTooltipsCallback,
  })
}

function onRemoveWatches(
  this: HTMLButtonElement,
  selectedRows: JQuery<HTMLTableRowElement>
) {
  const count = selectedRows.length
  if (count === 0) {
    return
  }

  const selectedWatches = selectedRows
    .map(function () {
      return $(this).data("watch-name")
    })
    .get()

  const modal = $(".modal.remove-watches-dialog").modal({
    selector: { deny: ".actions .cancel" },
    autofocus: false,
    onApprove: function () {
      const modal = $(this)
      modal.find(".button.approve").addClass("loading")

      $.ajax("/rpc.json/user/removeWatches", {
        type: "POST",
        contentType: "application/json",
        data: JSON.stringify({
          names: selectedWatches,
        }),
        success: function () {
          document.location.reload()
        },
      })
      return false
    },
  })

  const isSingular = count === 1
  modal.find("[data-view=singular]").toggle(isSingular)
  modal.find("[data-view=plural]").toggle(!isSingular)
  modal.find("[data-value=count]").text(count)

  modal.modal("show")
}

function initializeWatchListTable() {
  const table = $("table.watches.dataTable")
  if (!table.length) {
    return
  }

  const dt = table.DataTable({
    paging: true,
    pageLength: 50,
    lengthChange: false,
    ordering: true,
    searching: true,
    info: true,
    order: getPersistedOrder("watchesOrder", [[2, "asc"]], [0, 7]),
    drawCallback: createPersistOrderCallback("watchesOrder"),
    columnDefs: [
      { orderable: false, targets: 0 },
      { type: "string", targets: 1 },
      { type: "simple-date", targets: 5 },
      { type: "simple-date", targets: 6 },
      { orderable: false, targets: 7 },
    ],
    language: texts,
  })

  initializeDataTableSelect(dt, {
    buttons: [
      {
        text: trilingual(
          "Delete selected",
          "Auswahl löschen",
          "Effacer la sélection"
        ),
        title: trilingual(
          "Remove all selected entries from the watch list",
          "Alle ausgewählten Einträge von der Watch-Liste entfernen",
          "Supprimer toutes les entrées sélectionnées de la liste de surveillance"
        ),
        class: "ui button negative",
        icon: "alternate outline trash",
        clickCallback: onRemoveWatches,
      },
    ],
  })
}

function initializeDocumentsTable() {
  const table = $("table.documents.dataTable")
  if (!table.length) {
    return
  }

  const order = State.getValue("documentsOrder")
  const orderCallback = createPersistOrderCallback("documentsOrder")
  const pageLength = 25
  const rowCount = table.find("> tbody > tr").length
  const enablePagination = rowCount > pageLength
  table.DataTable({
    paging: enablePagination,
    pageLength: pageLength,
    lengthChange: false,
    ordering: true,
    order: order ?? [[0, "desc"]],
    columnDefs: [
      { type: "simple-date", targets: 0 },
      { type: "simple-date", targets: 3 },
      { orderable: false, targets: 4 },
    ],
    searching: true,
    language: texts,
    drawCallback: function () {
      orderCallback.apply(this)
      $(this).find(".tooltip-icon").popup({ position: "top center" })
    },
  })
}

export function initializeDocumentShopTable(element: JQuery) {
  patchPaginationRenderer()
  initializeSorting()

  const pageLength = 15
  const rowCount = element.find("> tbody > tr").length
  const enablePagination = rowCount > pageLength
  element.DataTable({
    paging: enablePagination,
    pageLength: pageLength,
    lengthChange: false,
    searching: false,
    ordering: true,
    info: false,
    autoWidth: false,
    order: [[2, "desc"]],
    columnDefs: [
      { orderable: false, targets: 0 },
      { type: "simple-date", targets: 2 },
    ],
    language: {
      ...texts,
      zeroRecords: trilingual(
        "No original documents are available for this company.",
        "Für diese Firma sind keine Originaldokumente verfügbar.",
        "Aucun document original n’est disponible pour cette entreprise."
      ),
    },
  })
}

export function initializeInvoicesTable(element: JQuery) {
  patchPaginationRenderer()
  initializeSorting()

  const pageLength = 8
  const rowCount = element.find("> tbody > tr").length
  const enablePagination = rowCount > pageLength
  element.DataTable({
    destroy: true,
    paging: enablePagination,
    pageLength: pageLength,
    lengthChange: false,
    searching: false,
    info: false,
    ordering: true,
    order: [[1, "desc"]],
    columnDefs: [
      { orderable: false, targets: 0 },
      { type: "simple-date", targets: 1 },
      { orderable: false, targets: 3 },
    ],
    language: texts,
    dom: "lfrtp",
  })
}

function initializeCompanyRepresentativesTable() {
  const table = $("table.company-representatives.dataTable")
  if (!table.length) {
    return
  }

  const pageLength = table.data("page-size") ?? 10
  const rowCount = table.find("> tbody > tr").length
  const enablePagination = rowCount > pageLength
  const dt = table
    .on("init.dt search.dt", function (_event, settings) {
      const api = new $.fn.dataTable.Api(settings)
      let previousPositionName: string | null = null
      const roleCells = $(api.rows({ search: "applied" }).nodes()).find(
        "td:first"
      )
      roleCells.each(function (index) {
        const cell = $(this)
        const roleElement = cell.find("> span")
        const positionName = roleElement.data("name")

        const isNewPosition = positionName !== previousPositionName
        const isFirstRowOfPage = index % pageLength === 0
        const visible = isFirstRowOfPage || isNewPosition
        cell.toggleClass("role-hidden", !visible)
        previousPositionName = positionName
      })
    })
    .DataTable({
      autoWidth: false,
      paging: enablePagination,
      pageLength: pageLength,
      lengthChange: false,
      ordering: false,
      searching: true,
      info: false,
      language: texts,
      // Overwrite dom structure to hide default search input
      dom: buildDomLayout({ hideHeader: true }),
    })

  // Attach search function to custom search input
  $(dt.table(null).header())
    .find("input[type='search']")
    .on("keyup", function () {
      const query = $(this).val() as string
      dt.search(query).draw()
    })
}

export function initializeDataTables() {
  $.extend(true, $.fn.dataTable.defaults, {
    // Customizes footer layout for improved pagination on mobile
    dom: buildDomLayout(),
    // Remove spacing once the datatable has rendered
    initComplete: function () {
      $(this).removeClass("dt-spacing")
    },
  })

  patchPaginationRenderer()
  initializeSorting()

  $(document).on("init.dt", function (e, ctx: DataTables.SettingsLegacy) {
    if (e.namespace !== "dt") {
      return
    }

    const api = new $.fn.dataTable.Api(ctx)
    const inputs = $(
      "div.dataTables_filter .ui.input",
      api.tables().containers()
    )

    inputs
      .not(".labeled")
      .addClass("left icon labeled")
      .prepend("<i class='icon search'></i>")
  })

  initializeInnovationRanking()
  initializeCoverageTables()
  initializeWatchListTable()
  initializeDocumentsTable()
  initializeCompanyRepresentativesTable()
}
