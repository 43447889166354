// See site-vars.less

export const blue = "#007388"
export const blueHover = "#005d6f"
export const grey = "#a3b3c0"
export const greyHover = "#b2c0ca"
export const teal = "#7fb5c2"
export const tealHover = "#6aadbe"
export const olive = "#a0b06c"
export const oliveHover = "#97ab58"
