import $ from "jquery"
import { getLanguage } from "../../util/language"
import { geoapifyApiKey } from "../api"

export interface AddressSearch {
  applyFilter(countries: string[]): void
}

interface Feature {
  properties: {
    address_line1: string
    address_line2: string
    formatted: string
    lat: number
    lon: number
  }
  bbox?: [number, number, number, number]
}

interface ResponseResult {
  title: string
  description: string
  value: string
  coord: string
  bbox?: string
}

export function initializeAddressSearch(
  searchElement: HTMLElement
): AddressSearch {
  let filteredCountries: string[] = []
  const addressInput = searchElement.querySelector<HTMLInputElement>(
    `input[name="address"]`
  )!
  const coordInput =
    searchElement.querySelector<HTMLInputElement>(`input[name="coord"]`)!
  const bboxInput =
    searchElement.querySelector<HTMLInputElement>(`input[name="bbox"]`)!

  const searchAutocomplete = $(searchElement).search({
    minCharacters: 3,
    showNoResults: false,
    apiSettings: {
      url: `https://api.geoapify.com/v1/geocode/autocomplete?text={query}&lang={lang}&limit={limit}&filter={filter}&apiKey={apiKey}`,
      beforeSend(settings: FomanticUI.APISettings) {
        settings.urlData = {
          ...settings.urlData,
          lang: getLanguage(),
          limit: 5,
          apiKey: geoapifyApiKey,
          filter: `countrycode:${
            filteredCountries.length > 0 ? filteredCountries.join(",") : "none"
          }`,
        }
        return settings
      },
      onResponse(response) {
        if (Array.isArray(response?.features)) {
          const results: ResponseResult[] = response.features.map(
            (feature: Feature): ResponseResult => ({
              title: feature.properties.address_line1,
              description: feature.properties.address_line2,
              value: feature.properties.formatted,
              coord: [feature.properties.lat, feature.properties.lon].join("|"),
              bbox: feature.bbox?.join("|"),
            })
          )
          return { results }
        }
        return false
      },
    },
    onSelect(result: ResponseResult) {
      searchAutocomplete.search("set value", result.value)
      coordInput.value = result.coord
      bboxInput.value = result.bbox ?? ""
    },
  })

  addressInput.addEventListener("change", () => {
    coordInput.value = ""
    bboxInput.value = ""
  })

  return {
    applyFilter(countries: string[]) {
      filteredCountries = countries
      searchAutocomplete.search("clear cache")
    },
  }
}
