import { trilingual } from "../../viz/i18n"

const texts = {
  selectAll: trilingual(
    "Select all entries",
    "Alle Einträge auswählen",
    "Sélectionner toutes les entrées"
  ),
  deselectAll: trilingual(
    "Deselect all entries",
    "Alle Einträge abwählen",
    "Désélectionner toutes les entrées"
  ),
  select: trilingual(
    "Select entry",
    "Eintrag auswählen",
    "Sélectionner une entrée"
  ),
  deselect: trilingual(
    "Deselect entry",
    "Eintrag abwählen",
    "Désélectionner une entrée"
  ),
}

export function initializeDataTableSelect(
  dt: DataTables.Api,
  options: SelectionOptions
) {
  const settings = dt.settings()[0]
  if (settings._columnSelection) {
    return
  }
  setupTableSelection(dt, options)
}

interface SelectionOptions {
  buttons: {
    text: string
    class: string
    title?: string
    icon?: string
    clickCallback: (selectedRows: JQuery<HTMLTableRowElement>) => void
  }[]
}
interface SettingsWithSelection extends DataTables.SettingsLegacy {
  _columnSelection: number[]
  _selectionButtons: Node[]
  _selectionButtonOptions: SelectionOptions["buttons"]
  _headerCheckbox: Node
  aoData: RowLegacyWithSelection[]
}
interface RowLegacyWithSelection extends DataTables.RowLegacy {
  _isSelected: boolean
}

function setupTableSelection(dt: DataTables.Api, options: SelectionOptions) {
  const settings = dt.settings()[0] as SettingsWithSelection
  const container = $(dt.table(null).container())

  settings._headerCheckbox = $(container).find("th input:checkbox")[0]
  settings._columnSelection = []
  settings._selectionButtons = []
  settings._selectionButtonOptions = options.buttons

  // Patch search box container and add buttons
  const buttonContainer = container.find(
    ".ui.stackable.grid > .row:first .button-container"
  )
  for (const buttonOptions of settings._selectionButtonOptions) {
    const button = $("<button/>", {
      title: buttonOptions.title,
      class: buttonOptions.class,
      disabled: true,
    }).on("click", function () {
      const context = this
      const selectedRows = $(
        settings._columnSelection.map(
          (index) => settings.aoData[index].nTr as HTMLTableRowElement
        )
      )
      buttonOptions.clickCallback.call(context, selectedRows)
    })

    if (buttonOptions.icon) {
      button.append($("<i/>", { class: `${buttonOptions.icon} icon` }))
    }
    button.append($("<span/>", { text: buttonOptions.text }))

    settings._selectionButtons.push(button[0])
    buttonContainer.prepend(button)
  }

  // Setup row checkboxes
  dt.rows()
    .indexes()
    .each(function (index) {
      $(settings.aoData[index].nTr)
        .find("input:checkbox")
        .prop("checked", false)
        .prop("title", texts.select)
        .prop("aria-label", texts.select)
        .on("click", function (e) {
          const cell = dt.cell($(e.target).closest("td"))
          if (cell.any()) {
            toggleRow(dt, cell.index().row)
            onSelectionChange(dt)
          }
        })
    })

  // Setup header checkbox
  $(settings._headerCheckbox).on("change", function () {
    const checkbox = $(this)
    if (checkbox.prop("checked")) {
      dt.rows()
        .indexes()
        .each(function (index) {
          selectRow(dt, index)
        })
    } else {
      dt.rows()
        .indexes()
        .each(function (index) {
          deselectRow(dt, index)
        })
    }
    onSelectionChange(dt)
  })

  // Initial update of selection elements
  onSelectionChange(dt)
}

function onSelectionChange(dt: DataTables.Api) {
  updateHeaderCheckbox(dt)
  updateButtons(dt)
}

function selectRow(dt: DataTables.Api, rowIndex: number) {
  const settings = dt.settings()[0] as SettingsWithSelection
  const rowData = settings.aoData[rowIndex]

  if (!rowData._isSelected) {
    rowData._isSelected = true
    const index = settings._columnSelection.indexOf(rowIndex)
    if (index === -1) {
      settings._columnSelection.push(rowIndex)
    }

    $(rowData.nTr)
      .addClass("selected")
      .find("input:checkbox")
      .prop("checked", true)
      .prop("title", texts.deselect)
      .prop("aria-label", texts.deselect)
  }
}

function deselectRow(dt: DataTables.Api, rowIndex: number) {
  const settings = dt.settings()[0] as SettingsWithSelection
  const rowData = settings.aoData[rowIndex]

  if (rowData._isSelected) {
    rowData._isSelected = false
    const index = settings._columnSelection.indexOf(rowIndex)
    if (index !== -1) {
      settings._columnSelection.splice(index, 1)
    }

    $(rowData.nTr)
      .removeClass("selected")
      .find("input:checkbox")
      .prop("checked", false)
      .prop("title", texts.select)
      .prop("aria-label", texts.select)
  }
}

function toggleRow(dt: DataTables.Api, rowIndex: number) {
  const settings = dt.settings()[0] as SettingsWithSelection
  const rowData = settings.aoData[rowIndex]

  if (rowData._isSelected) {
    deselectRow(dt, rowIndex)
  } else {
    selectRow(dt, rowIndex)
  }
}

function updateHeaderCheckbox(dt: DataTables.Api) {
  const settings = dt.settings()[0] as SettingsWithSelection
  const count = settings.aoData.length
  const selected = settings._columnSelection.length

  const indeterminate = selected > 0 && selected < count
  const checked = selected > 0 && selected === count
  const title = checked ? texts.deselectAll : texts.selectAll

  $(settings._headerCheckbox)
    .prop("indeterminate", indeterminate)
    .prop("checked", checked)
    .prop("title", title)
    .prop("aria-label", title)
}

function updateButtons(dt: DataTables.Api) {
  const settings = dt.settings()[0] as SettingsWithSelection
  const selected = settings._columnSelection.length

  $(settings._selectionButtons).each(function (index) {
    const options = settings._selectionButtonOptions[index]
    const button = $(this)
    button
      .prop("disabled", selected === 0)
      .find("span")
      .text(options.text + " (" + selected + ")")
  })
}
