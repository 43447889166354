import $ from "jquery"
import { trilingual } from "../../viz/i18n"

export function recreate(segmentCodeStandard: string) {
  const segmentCodeDropdown = $(".ui.segment-code.dropdown")

  segmentCodeDropdown.dropdown({
    apiSettings: {
      url: "/segments.json?standard={standard}",
      urlData: {
        standard: segmentCodeStandard,
      },
      onResponse(response: [string, string][]) {
        return {
          success: true,
          results: response.map(([code, description]: [string, string]) => ({
            name: `<div class="code"><div class="ui circular horizontal label">${code}</div></div><span>${description}</span>`,
            value: code,
            text: `${code} ${description}`,
          })),
        }
      },
    },
    saveRemoteData: false,
    filterRemoteData: true,
    // There seems to be a bug on touch devices, so we can't use this feature.
    // minCharacters: 2,
    message: {
      noResults: trilingual("No results", "Keine Resultate", "Aucun résultats"),
    },
    fullTextSearch: "exact",
    delimiter: "|",
    direction: "downward",
  })

  // Restore dropdown values from hidden input (e.g. search result view)
  if (!!segmentCodeDropdown.dropdown("get value")) {
    segmentCodeDropdown.dropdown("queryRemote", "", () => {
      segmentCodeDropdown.dropdown("restore selected")
    })
  }
}

export function clear() {
  $(".ui.segment-code.dropdown").dropdown("clear")
}
